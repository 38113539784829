import { ConfirmationModalProps } from './ConfirmationModal';
import { ContactModalProps } from './ContactModal';
import { ContentModalProps } from './ContentModal';
import { PromptModalProps } from './PromptModal';
export type ModalType = 'confirmation' | 'prompt' | 'contact' | 'content';

export interface Modal {
  id: string;
  type: ModalType;
  props: any;
}

export interface ConfirmationModal extends Modal {
  type: 'confirmation';
  props: ConfirmationModalProps;
}
export interface PromptModal extends Modal {
  type: 'prompt';
  props: PromptModalProps;
}

export interface ContactModal extends Modal {
  type: 'contact';
  props: ContactModalProps;
}

export interface ContentModal extends Modal {
  type: 'content';
  props: ContentModalProps;
}

export interface ModalState {
  modals: Modal[];
}

export interface ModalContext {
  state: ModalState;
  dispatch: React.Dispatch<any>;
}

export enum ActionType {
  ADD_MODAL = 'ADD_MODAL',
  REMOVE_MODAL = 'REMOVE_MODAL',
}

export interface Action {
  type: ActionType;
  payload: any;
}
