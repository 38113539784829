import { ReactNode } from 'react';

export interface ContentModalProps {
  children?: ReactNode;
  onDismiss?: () => void;
}

export const ContentModal = ({ children }: ContentModalProps) => {
  return (
    <div data-testid="content-modal" id="content-modal">
      {children}
    </div>
  );
};
