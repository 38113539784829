import dynamic from 'next/dynamic';

import { IconProps as Props } from 'src/theme/types';

const AvatarIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.AvatarIcon),
);
const ErrorIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.ErrorIcon),
);
const SuccessIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.SuccessIcon),
);
const CalendarIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.CalendarIcon),
);
const NoAccessIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.NoAccessIcon),
);
const RobotIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.RobotIcon),
);
const DeclineIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.DeclineIcon),
);
const ChevronIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.ChevronIcon),
);
const WarningIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.WarningIcon),
);
const SearchIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.SearchIcon),
);
const CrossIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.CrossIcon),
);
const HamburgerIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.HamburgerIcon),
);
const CheckIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.CheckIcon),
);
const VulnerableIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.VulnerableIcon),
);
const ClockIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.ClockIcon),
);
const SpinnerIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.SpinnerIcon),
);
const JobsIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.JobsIcon),
);
const LogoutIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.LogoutIcon),
);
const QuoteIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.QuoteIcon),
);
const ImpersonationHatIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.ImpersonationHatIcon),
);
const InvoicesIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.InvoicesIcon),
);
const FilterIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.FilterIcon),
);
const TickIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.TickIcon),
);
const TickCircleIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.TickCircleIcon),
);
const LockIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.LockIcon),
);
const TrashIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.TrashIcon),
);
const DangerIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.DangerIcon),
);
const NotificationsIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.NotificationsIcon),
);
const NotificationsUnreadIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.NotificationsUnreadIcon),
);
const PlusIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.PlusIcon),
);
const UnavailableCalendarIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.UnavailableCalendarIcon),
);
const CheckboxCrossIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.CheckboxCrossIcon),
);
const AlertIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.AlertIcon),
);
const SpannerIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.SpannerIcon),
);
const CallIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.CallIcon),
);
const NotInterestedIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.NotInterestedIcon),
);
const TelephoneIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.TelephoneIcon),
);
const CopyIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.CopyIcon),
);
const DownloadIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.DownloadIcon),
);
const PreviewIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.PreviewIcon),
);
const ActivityHistoryIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.ActivityHistoryIcon),
);
const HistoryIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.HistoryIcon),
);
const TimerIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.TimerIcon),
);

const LogoIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.LogoIcon),
);

const TeamIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.TeamIcon),
);

const KebabIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.KebabIcon),
);

const CertificateIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.CertificateIcon),
);

const DocumentIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.DocumentIcon),
);

const ClipboardTickIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.ClipboardTickIcon),
);
const ClipboardAlertIcon = dynamic<Props>(() =>
  import('../Icons').then((mod) => mod.ClipboardAlertIcon),
);
const icons = {
  '': undefined,
  avatar: AvatarIcon,
  error: ErrorIcon,
  success: SuccessIcon,
  calendar: CalendarIcon,
  'no-access': NoAccessIcon,
  robot: RobotIcon,
  decline: DeclineIcon,
  chevron: ChevronIcon,
  warning: WarningIcon,
  search: SearchIcon,
  cross: CrossIcon,
  notifications: NotificationsIcon,
  notificationsUnread: NotificationsUnreadIcon,
  hamburger: HamburgerIcon,
  check: CheckIcon,
  vulnerable: VulnerableIcon,
  clock: ClockIcon,
  spinner: SpinnerIcon,
  jobs: JobsIcon,
  logout: LogoutIcon,
  quote: QuoteIcon,
  invoices: InvoicesIcon,
  impersonationHat: ImpersonationHatIcon,
  filter: FilterIcon,
  tick: TickIcon,
  tickCircle: TickCircleIcon,
  logo: LogoIcon,
  lock: LockIcon,
  trash: TrashIcon,
  danger: DangerIcon,
  plus: PlusIcon,
  unavailableCalendar: UnavailableCalendarIcon,
  checkboxCross: CheckboxCrossIcon,
  alert: AlertIcon,
  spanner: SpannerIcon,
  call: CallIcon,
  notInterested: NotInterestedIcon,
  telephone: TelephoneIcon,
  copy: CopyIcon,
  download: DownloadIcon,
  preview: PreviewIcon,
  activityHistory: ActivityHistoryIcon,
  history: HistoryIcon,
  timer: TimerIcon,
  team: TeamIcon,
  kebab: KebabIcon,
  certificate: CertificateIcon,
  document: DocumentIcon,
  clipboardTick: ClipboardTickIcon,
  clipboardAlert: ClipboardAlertIcon,
} as const;

export type IconName = keyof typeof icons;

interface IconProps extends Props {
  name: IconName;
}

export const Icon = ({ name, ...props }: IconProps) => {
  const Icon = icons[name];
  if (Icon) {
    return <Icon {...props}></Icon>;
  }
  return null;
};
